import React, {useState, useEffect} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from 'mdb-react-ui-kit';
import '../styles/index.css';
import serviceImg1 from '../images/services.png';
import serviceImg2 from '../images/services_2.jpg';
import serviceImg3 from '../images/services_3.jpg';
import AOS from 'aos';
 
import { useTranslation } from "react-i18next";

export default function About() {
    const { t, i18n } = useTranslation();

    AOS.init({
        offset: 200,
        duration: 600,
        easing: 'ease-in-sine',
        delay: 100,
        once: true
      });

    return (
        <MDBContainer className='about-container' id='about-container'>
            <MDBRow className='border-row' data-aos="fade-up">
                <MDBCol md='12' className='col-about text-start mb-0'>
                    <MDBTypography tag='div' className='display-6 pb-3 mb-3 border-bottom'>
                        {t("about.name")}
                    </MDBTypography>
                    <MDBTypography className='lead about-paragraph'>
                        {t("about.description")}
                    </MDBTypography>
                </MDBCol>
            </MDBRow>
            <MDBRow className='gy-3 about-images' data-aos="fade" data-aos-duration='1000'>
                <MDBCol md='6' className='col-img'>
                    <img src={serviceImg3} className='img-fluid rounded' alt='...' />
                </MDBCol>
                <MDBCol md='3' className='col-img'>
                    <img src={serviceImg1} className='img-fluid rounded' alt='...' />
                </MDBCol>
                <MDBCol md='3' className='col-img'>
                    <img src={serviceImg2} className='img-fluid rounded' alt='...' />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );  
}
