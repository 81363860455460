import React, {useState, useEffect} from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBTypography, MDBIcon } from 'mdb-react-ui-kit';
import '../styles/index.css';
import { useTranslation } from "react-i18next";

export default function Contact() {
    
    const { t, i18n } = useTranslation();

    return (
        <MDBContainer id='contact-container'>
            <MDBTypography tag='div' className='display-6 pb-3 mb-3 border-bottom text-center' data-aos="fade-up">
                {t("contacts.name")}
            </MDBTypography>
            <MDBRow className='g-2 contact-row' data-aos="fade-up" data-aos-duration='700' data-aos-delay="200">
                <MDBCol md='6' className='contact-column'>
                    <MDBIcon fas icon="mobile-alt" size='3x'/>
                    <MDBTypography className='lead mb-0 contact-text p-3'> {t("contacts.1.name")} <span className='colored-contact'><br></br>+373-795-12300<br></br>+40-746-082-572</span></MDBTypography>
                </MDBCol>
                <MDBCol md='6' className='contact-column'>
                    <MDBIcon far icon="envelope" size='3x'/>
                    <MDBTypography className='lead mb-0 contact-text p-3'>  {t("contacts.2.name")} <span className='colored-contact'><br></br>acicanci@gmail.com</span></MDBTypography>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}