import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en.json';
import ro from './translations/ro.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: { order: ["path", "navigator"] },
    whitelist: ["en", "ro"],
    resources: {
      en: {
        translation: en,
      },
      ro: {
        translation: ro,
      },
    },
    fallbackLng: 'ro',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
  });

export default i18n;