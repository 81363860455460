import React, {useState} from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import '../styles/index.css';

const ScrollArrow = () =>{

    const [showScroll, setShowScroll] = useState(true)
  
    const checkScrollBottom = () => {
      if (window.pageYOffset > 50){
        setShowScroll(false)
      }
      else if (window.pageYOffset < 50) {
        setShowScroll(true)
      }
    };
  
    const scrollBottom = () =>{
      window.scrollTo({
        top: 700,
        behavior: 'smooth'
      });
    };
  
    window.addEventListener('scroll', checkScrollBottom)
  
    return (
        <MDBIcon fas icon="chevron-circle-down" className="scrollTop" size='4x' onClick={scrollBottom} style={{display: showScroll ? 'flex' : 'none'}}/>
    );
  }
  
  export default ScrollArrow;