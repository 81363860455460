import React, {useState} from 'react';
import '../styles/index.css';
import {MDBFooter, MDBTypography, MDBRow, MDBCol} from 'mdb-react-ui-kit';

export default function Footer() {
    return (
        <MDBFooter id='footer-container' className='text-center container-fluid'>
            <MDBRow className="row footer-row">
                <MDBCol md='12' id="copyright-text">
                    <MDBTypography className='lead mb-0 p-3 text-light' id="copyright">©{new Date().getFullYear()}, Copyright: Solexpress, SRL.</MDBTypography>
                </MDBCol>
            </MDBRow>
        </MDBFooter>
    );
}