import React, {useState, useEffect} from 'react';
import { MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';
import '../styles/index.css';
import colabImage1 from '../images/colab1.png';
import colabImage2 from '../images/colab2.png';
import colabImage3 from '../images/colab3.svg';
import colabImage4 from '../images/colab4.png';

export default function Colaboration() {
    return (
        <MDBContainer id='sponsors-container'>
            <MDBRow className='g-2 g-lg-3 gy-5 sponsors-row' data-aos="fade" data-aos-duration='1000'>
                <MDBCol md='3'>
                <img src={colabImage1} className='img-fluid' alt='...' />
                </MDBCol>
                <MDBCol md='3'>
                <img src={colabImage2} className='img-fluid' alt='...' />
                </MDBCol>
                <MDBCol md='3'>
                <img src={colabImage3} className='img-fluid' alt='...' />
                </MDBCol>
                <MDBCol md='3'>
                <img src={colabImage4} className='img-fluid' alt='...' />
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}