import '../styles/index.css';
import { useTranslation } from "react-i18next";

export default function Panorama() {
    const { t, i18n } = useTranslation();
    return (
        <div className='p-5 text-center bg-image' data-aos="fade">
            <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                <div className='d-flex justify-content-center align-items-center h-100'>
                    <div className='text-white text-on-panorama'>
                        <h1 className='mb-3 display-2'>
                            {t("header.name")}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
}