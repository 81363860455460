import React, { useState, useEffect } from 'react';
import '../styles/index.css';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse
} from 'mdb-react-ui-kit';
import Logo from '../images/Logo-v2-cropped.png';
import { useTranslation } from "react-i18next";

export default function Header() {
    const { t, i18n } = useTranslation();

    const [showBasic, setShowBasic] = useState(false);
    const [language, setDLanguage] = useState();
   

    function setLanguage() {
        if (language == 'English')
        {
            setDLanguage('Română');
            i18n.changeLanguage('ro');
        }
        else
        {
            setDLanguage('English');
            i18n.changeLanguage('en');
        }
    }

    useEffect(() => {
        if (i18n.language == 'ro')
            setDLanguage("Română");
        else
            setDLanguage("English");
    }, []);

    return (
        <MDBNavbar expand='lg' light bgColor='light'>

            <MDBContainer fluid>
                <MDBNavbarBrand href='.'>
                    <img
                        src={Logo}
                        height='60'
                        alt=''
                        loading='lazy'
                    />
                    SOLEXPRESS
                </MDBNavbarBrand>

                <MDBNavbarToggler
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShowBasic(!showBasic)}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={showBasic}>
                    <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0' id='navbar-right-content'>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='#about-container'>{t("about.tag")}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='#service-container'>{t("services.tag")}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='#contact-container'>{t("contacts.tag")}</MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem className='vertical-line'>
                            <MDBDropdown>
                                <MDBDropdownToggle tag='a' className='nav-link'>
                                    {language}
                                </MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    <MDBDropdownItem>
                                        <MDBDropdownLink onClick={() => setLanguage()}>
                                            {language == 'English' ? 'Română' : 'English'}
                                        </MDBDropdownLink>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
}