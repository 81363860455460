import React from 'react';
import '../styles/index.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Panorama from '../components/Panorama';
import ScrollArrow from '../components/ScrollArrow';
import About from '../components/About';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Colaboration from '../components/Colaboration';

function App() {
  return (
    <div className='App'>
      <React.Fragment>
            <Header/>
            <Panorama/>
            <ScrollArrow/>
            <About/>
            <Services/>
            <Contact/>
            <Colaboration/>
            <Footer/>
        </React.Fragment>
    </div>

  );
}

export default App;
