import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from 'mdb-react-ui-kit';
import '../styles/index.css';
import { useTranslation } from "react-i18next";

export default function Services() {
    const { t, i18n } = useTranslation();

    return (
        <MDBContainer id='service-container'>
            <MDBTypography tag='div' className='display-6 pb-3 mb-3 border-bottom' data-aos="fade-up">
            {t("services.name")}
            </MDBTypography>
            <MDBRow className='g-2' data-aos="fade-up" data-aos-duration='700' data-aos-delay="200">
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.1.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.1.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.2.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.2.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.3.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.3.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.4.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.4.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.5.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.5.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
                <MDBCol md='4'>
                    <div className='p-3 col-service'>
                        <MDBTypography variant='h3' tag='div' className='p-3 mb-3 service-name'>
                            {t("services.6.name")}
                        </MDBTypography>
                        <MDBTypography className='lead mb-0'>
                            {t("services.6.description")}
                        </MDBTypography>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}